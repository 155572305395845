import { NAV_ITEMS } from "../../constants"

export const mapNavItems = (nodes, footer = false) => {
  const category = nodes?.[0].category?.toLowerCase()?.replace(" ", "-")
  const subCategory = nodes?.[0].subCategories?.[0]?.toLowerCase()?.replace(" ", "-")
  return NAV_ITEMS.map(item => {
    if (item.id === 2) {
      return {
        ...item,
        ...(footer ? { location: `/our-work/${category}/${subCategory}` } : {}),
        children: nodes.map(({ category, subCategories }, idx) => ({
          id: idx,
          location: `/our-work/${category.toLowerCase().replaceAll(" ", "-")}/${subCategories?.[0]?.toLowerCase()?.replace(" ", "-")}/`,
          name: category
        }))
      }
    }
    return { ...item }
  })
}