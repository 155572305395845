import React from "react"
import styled, { useTheme } from "styled-components"
import { graphql, Link, useStaticQuery } from "gatsby"
import { SOCIAL_MEDIA_ICON } from "../constants"
import { FlexContainer, FlexItem } from "../styles/layout.styled"
import Logo from "../assets/images/Logo.png"
import { mapNavItems } from "../utils/mappers/mapNavItems"

const FooterContainer = styled(FlexContainer)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 48px 14px;
  max-width: 1294px;
  margin: 0 auto;

  .heading {
    font-size: 20px;
    line-height: 23.4px;
    margin: 0 0 24px;
    color: ${({ theme }) => theme.white};
  }
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    .heading {
      font-size: 14px;
      font-weight: 600;
    }
    .Links-container {
      width: 40%;
    }
    .links-info-container {
      justify-content: space-around;
      width: 70%;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 14px;

    .Links-container {
      width: 50%;
    }

    .links-info-container {
      padding: 0;
      display: flex;

      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
    .heading {
      margin: 0 0 10px;
    }
  }
`
const StyledLeftItem = styled(FlexItem)`
  .left {
    display: flex;
    flex-direction: column;

    .icon-container {
      margin-right: 20px;
    }
    > a {
      width: 72px;
    }
    .logo {
      > img {
        width: 100%;
        height: 36px;
      }
    }
    .no-margin {
      margin: 0;
    }

    .text {
      width: 100%;
      font-size: 12px;
      font-weight: 100;
      line-height: 20px;
      color: ${({ theme }) => theme.white};
      margin: 0;
    }
    .text-container {
      width: 30%;
      margin: 20px 0 40px;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    height: 100%;
    width: 35%;
    margin: 0;
    .left {
      .text {
        width: 100%;
      }
      .text-container {
        width: 100%;
      }
    }

    .icon-container {
      margin: 0;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    .left {
      .logo {
        > img {
          width: 72px;
          height: 36px;
        }
      }
      .text {
        width: 100%;
        font-size: 12px;
      }
      .text-container {
        margin: 20px 0;
      }
      .social-media-container {
        margin-bottom: 10px;
        padding: 0 0 10px;
        border-bottom: 1px solid ${({ theme }) => theme.orange};
      }
    }
  }
`

const StyledCopyrightItem = styled(FlexContainer)`
  justify-content: center;
  padding: 0 0 24px;
  .copyright {
    color: ${({ theme }) => theme.white};
    opacity: 0.4;
    font-size: 14px;
    font-weight: 100;
  }
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    .copyright {
      margin: 0 0 5px 0;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    .copyright {
      margin: 0 0 5px 0;
      font-size: 12px;
    }
  }
`

const StyledLink = styled(Link)`
  font-size: 14px;
  font-weight: 100;
  text-decoration: none;
  color: ${({ theme }) => theme.white};
  width: 50%;
  margin-bottom: 20px;

  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    font-size: 12px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 10px;
    margin: 0 0 20px 0;
  }
`

const StyledContactInfoItem = styled(FlexContainer)`
  color: ${({ theme }) => theme.white};
  flex-direction: column;
  align-items: flex-start;
  width: 45%;
  > a {
    font-size: 12px;
    color: ${({ theme }) => theme.white};
    text-decoration: none;
    font-weight: 100;
    &:hover {
      text-decoration: underline;
    }
  }
  .with-margin {
    margin-bottom: 16px;
  }
  .contact-info {
    font-size: 14px;
    margin: 0 0 5px;
    font-weight: 100;
  }
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    width: 35%;

    .contact-info {
      font-size: 12px;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    width: 40%;
    .contact-info {
      font-size: 10px;
    }
    > a {
      font-size: 10px;
    }
  }
`

const renderedSocialMediaIcons = SOCIAL_MEDIA_ICON.map(({ id, icon, href }) => (
  <a
    key={id}
    href={href}
    target="_blank"
    rel="noreferrer"
    className={`${
      SOCIAL_MEDIA_ICON[SOCIAL_MEDIA_ICON.length - 1].id === id
        ? "icon-container no-margin"
        : "icon-container"
    }`}
  >
    {icon}
  </a>
))


const getCategories = graphql`
{
  categories: allContentfulCategories(sort: {fields : createdAt, order: ASC}) {
    nodes {
      category,
      subCategories
    }
  }
}
`

const Footer = () => {
  const { lightBlue } = useTheme()
  const { categories: { nodes } } = useStaticQuery(getCategories)

  const navItems = mapNavItems(nodes, true)

  return (
    <FlexContainer flexDirection="column" bg={lightBlue}>
      <FooterContainer>
        <StyledLeftItem>
          <div className="left">
            <Link to="/" className="logo">
              <img src={Logo} alt="logo" />
            </Link>
            <div className="text-container">
              <p className="text">
                We are a creative-services provider that specializes in
                producing video content, commercial photography, and animation
                for companies and corporations.
              </p>
            </div>
            <div className="social-media-container">
              {renderedSocialMediaIcons}
            </div>
          </div>
        </StyledLeftItem>

        <FlexContainer
          width="45%"
          justifyContent="space-between"
          alignItems="flex-start"
          className="links-info-container"
        >
          <FlexContainer
            width="40%"
            flexDirection="column"
            alignItems="flex-start"
            className="Links-container"
          >
            <p className="heading">Useful Links</p>
            <FlexContainer flexWrap tabletFlexWrap justifyContent='flex-start'>
              {navItems?.map(({id, location, name}) => (
                <StyledLink to={location} key={id}>
                  {name}
                </StyledLink>
              ))}
            </FlexContainer>
          </FlexContainer>

          <StyledContactInfoItem>
            <p className="heading"> Get in Touch</p>

            <p className="contact-info">Phone:</p>
            <a
              className="with-margin"
              href="tel:+374 96 345245"
              target="_blank"
              rel="noreferrer noopener"
            >
              +374 96 345245
            </a>
            <p className="contact-info">Email:</p>
            <a
              className="with-margin"
              href="mailto:+374 96 345245"
              target="_blank"
              rel="noreferrer noopener"
            >
              info@megproduction.com
            </a>
          </StyledContactInfoItem>
        </FlexContainer>
      </FooterContainer>

      <StyledCopyrightItem>
        <p className="copyright">
          Copyright &copy;{new Date(Date.now()).getFullYear()} MEGProduction
        </p>
      </StyledCopyrightItem>
    </FlexContainer>
  )
}

export default Footer
