import React from "react"
import { ThemeProvider } from "styled-components"
import { GlobalStyles } from "../styles/global"
import { THEME } from "../styles/theme"
import Header from "./Header"
import { FlexContainer } from "../styles/layout.styled"
import Footer from "./Footer"

const LayoutRoot = ({
  children,
  withHeader = true,
  withFooter = true,
  background,
}) => {
  return (
    <>
      <ThemeProvider theme={THEME}>
        <GlobalStyles />
        <FlexContainer flexDirection="column">
          {withHeader && <Header background={background} />}
          {children}
        </FlexContainer>
        {withFooter && <Footer />}
      </ThemeProvider>
    </>
  )
}

export default LayoutRoot
