import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import Arrow from '../../assets/svg/arrow.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ isOpen }) => isOpen ? "18px" : "0"};
`

const AccordionButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 100;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0;
  color: ${({theme}) => theme.white};
  font-family: 'Poppins', sans-serif;
`

const AccordionContent = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  transition: max-height 0.3s linear;
  
`

const StyledArrow = styled(Arrow)`
 transform: ${({ open }) => open ? "rotate(-90deg)": "rotate(90deg)"};
 transition: all 0.3s ease-in-out; 
`

const Accordion = ({ content, children}) => {
 const [isOpen, setIsOpen] = useState(false)
  const contentRef = useRef(null)

 const toggleAccordion = () => {
   setIsOpen(!isOpen)
 }

  useEffect(() => {
    function handleResize() {
      if (isOpen) {
        contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`
      } else {
        contentRef.current.style.maxHeight = '0'
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isOpen])

 return (
   <Container isOpen={isOpen}>
     <AccordionButton onClick={toggleAccordion}>
       {children}  <StyledArrow open={isOpen} />
     </AccordionButton>
     <AccordionContent ref={contentRef} isOpen={isOpen} role='ul'>
       {content}
     </AccordionContent>
   </Container>
 )
}

export default Accordion
