import React, { useRef, useState } from "react"
import styled, { keyframes } from "styled-components"
import Arrow from "../../assets/svg/arrow.svg"

const PopoverContainer = styled.div`
  position: relative;
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  z-index: 20;
  width: fit-content;
  white-space: nowrap;
  font-weight: 100;
`

const PopoverContent = styled.div`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  background: ${({ theme }) => theme.lightBlue};
  border-radius: 4px;
  padding: 14px;
  animation: ${props => (props.show ? fadeIn : fadeOut)} 0.4s ease;
  display: ${props => (props.show ? "flex" : "none")};
  flex-direction: column;
  gap: 14px;
  outline: none;

  &:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: ${({ theme }) => `14px solid ${theme.lightBlue}`};
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

const StyledArrow = styled(Arrow)`
 transform: ${({ open }) => open ? "rotate(-90deg)": "rotate(90deg)"};
 transition: all 0.3s ease; 
`

const Popover = ({ children, content }) => {
  const [show, setShow] = useState(false)
  const closeTimeout = useRef(null)

  const handleMouseEnter = () => {
    clearTimeout(closeTimeout.current)
    setShow(true)
  }

  const handleMouseLeave = () => {
    closeTimeout.current = setTimeout(() => {
      setShow(false)
    }, 200) // Adjust the delay as needed
  }

  const handleMouseMove = () => {
    clearTimeout(closeTimeout.current)
  }

  const handleKeyDown = e => {
    if (e.key === "Escape") {
      setShow(false)
    }

    if (e.key === "Enter" || e.key === " ") {
      setShow(true)
    }
  }

  return (
    <PopoverContainer
      tabIndex={0}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      onKeyDown={handleKeyDown}
    >
      <>{children} <StyledArrow open={show} /></>
      <PopoverContent show={show} onMouseMove={handleMouseMove}>
        {content}
      </PopoverContent>
    </PopoverContainer>
  )
}

export default Popover
