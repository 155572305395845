export const THEME = {
  body: {
    bg: "#122436",
    color: "#F36F21",
  },
  white: "#FFFFFF",
  black: "#000000",
  orange: "#fe653c",
  darkBlue: "#0F202F",
  lightBlue: "#122436",
  tablet: "1140px",
  mobile: "530px",
  secondaryHeaderBg: "rgba(18, 36, 54, 0.6)",
  error: "#FF3233",
  success: "#4caf50",
}
