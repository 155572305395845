import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Gilroy-Regular';
    src: local('Gilroy-Regular'), url(../fonts/Gilroy-Regular.ttf) format('truetype');
  }
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  html{
    height: 100%;
  }
  body {
    align-items: center;
    background: ${({ theme }) => theme.body.bg};
    color: ${({ theme }) => theme.body.color};
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    min-height: 100%;
    position: relative;
    overflow-x: hidden;
  }
  .react-player__preview {
    border-radius: 8px;
    box-shadow: 0 0 44px rgba(18, 36, 54, 0.6);
  }
`
