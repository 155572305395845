import { useEffect, useState } from 'react';

function useBreakpointQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);

    const handleMediaQueryChange = (event) => {
      setMatches(event.matches);
    };

    // Add an event listener to respond to changes in the media query
    mediaQueryList.addListener(handleMediaQueryChange);

    // Check the initial state of the media query
    setMatches(mediaQueryList.matches);

    // Clean up the event listener when the component unmounts
    return () => {
      mediaQueryList.removeListener(handleMediaQueryChange);
    };
  }, [query]);

  return matches;
}

export default useBreakpointQuery;
