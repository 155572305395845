import React, { useState } from "react"
import styled, { css } from "styled-components"
import Logo from "../assets/images/Logo.png"

import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import { FlexContainer, FlexItem } from "../styles/layout.styled"
import { SOCIAL_MEDIA_ICON } from "../constants"
import useHideScrollbar from "../hooks/useHideScrollbar"
import Popover from "./reusable/Popover"
import useBreakpointQuery from "../hooks/useBreakpointQuery"
import Accordion from "./reusable/Acorrdion"
import { mapNavItems } from "../utils/mappers/mapNavItems"

const activeStyle = {
  fontWeight: "bold"
}

const HeaderContainer = styled(FlexContainer)`
  align-items: center;
  padding: 24px 14px;
  position: absolute;
  background: ${({ background }) => background};
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;

  .left {
    flex: 1;

    > a {
      width: 72px;
      display: flex;

      > img {
        width: 100%;
        height: 36px;
      }
    }
  }

  .center {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }

  .right {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    .icon-container {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }

    .no-margin {
      margin: 0;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    padding: 24px 14px;
    .left {
      > a {
        > img {
          height: 36px;
        }
      }
    }

    .right {
      .icon-container {
        display: none;
      }
    }

    .center {
      flex: 2;
      position: fixed;
      gap: 42px;
      height: 100vh;
      width: 100%;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 5;
      transform: ${({ trigger }) =>
              trigger ? "translateX(0)" : "translateX(1781px)"};
      opacity: ${({ trigger }) => (trigger ? "1" : "0")};
      transition: all 0.4s ease-in-out;
      background: ${({ theme }) => theme.body.bg};
    }
  }
`

const StyledInnerContainer = styled(FlexContainer)`
  max-width: 1294px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
`

const StyledFlexItem = styled(FlexItem)`
  color: ${({ theme }) => theme.white};

  > a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 100;
    white-space: nowrap;
    color: ${({ theme }) => theme.white};
    transition: all 0.3s ease;

    &:hover {
      text-decoration: underline;
    }
  }
`

const StyledBurgerMenuWrapper = styled.div`
  display: none;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    width: 20px;
    height: 20px;
    z-index: 5;
    display: block;
    position: relative;
    float: right;
    transform: rotate(0deg);
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 20px;
      background: white;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.3s ease-in-out;
    }

    ${({ trigger }) =>
            trigger
                    ? css`
                      span:nth-of-type(1) {
                        top: 7px;
                        transform: rotate(135deg);
                      }

                      span:nth-of-type(2) {
                        opacity: 0;
                        left: -200px;
                        top: 7px;
                      }

                      span:nth-of-type(3) {
                        top: 7px;
                        transform: rotate(-135deg);
                      }
                    `
                    : css`
                      span:nth-of-type(1) {
                        top: 0;
                      }

                      span:nth-of-type(2) {
                        top: 6px;
                      }

                      span:nth-of-type(3) {
                        top: 12px;
                      }
                    `};
  }
`

const getCategories = graphql`
{
  categories: allContentfulCategories(sort: {fields : createdAt, order: ASC}) {
    nodes {
      category,
      subCategories
    }
  }
}
`

const Header = ({ background }) => {
  const [trigger, setTrigger] = useState(false)
  useHideScrollbar(trigger)
  const isSmallScreen = useBreakpointQuery("(max-width: 1140px)")
  const { categories: { nodes } } = useStaticQuery(getCategories)

  return (
    <HeaderContainer trigger={trigger} background={background}>
      <StyledInnerContainer>
        <div className="left">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <div role="menu" className="center">
          {mapNavItems(nodes).map(({ name, id, location, children }) =>
            children?.length ? (
              isSmallScreen ?
                <Accordion
                  key={id}
                  content={children.map(({ name, id, location }) => (
                    <StyledFlexItem
                      role="li"
                      key={id}
                      onClick={() => setTrigger(false)}
                    >
                      <Link activeStyle={activeStyle} to={location}>
                        {" "}
                        {name}
                      </Link>
                    </StyledFlexItem>
                  ))}
                >
                  {name}
                </Accordion>
                : <Popover
                  key={id}
                  content={children.map(({ name, id, location }) => (
                    <StyledFlexItem
                      role="li"
                      key={id}
                      onClick={() => setTrigger(false)}
                    >
                      <Link activeStyle={activeStyle} to={location}>
                        {" "}
                        {name}
                      </Link>
                    </StyledFlexItem>
                  ))}
                >
                  <StyledFlexItem
                    role="li"
                    key={id}
                    onClick={() => setTrigger(false)}
                  >
                    {name}
                  </StyledFlexItem>
                </Popover>
            ) : (
              <StyledFlexItem
                role="li"
                key={id}
                onClick={() => setTrigger(false)}
              >
                <Link activeStyle={activeStyle} to={location}>
                  {" "}
                  {name}
                </Link>
              </StyledFlexItem>
            )
          )}
        </div>
        <div className="right">
          {SOCIAL_MEDIA_ICON.map(({ icon, id, href }) => (
            <a
              className={`${
                SOCIAL_MEDIA_ICON[SOCIAL_MEDIA_ICON.length - 1].id === id
                  ? "icon-container no-margin"
                  : "icon-container"
              }`}
              key={id}
              href={href}
              target="_blank"
              rel="noreferrer noopener"
            >
              {icon}
            </a>
          ))}
          <StyledBurgerMenuWrapper
            trigger={trigger}
            onClick={() => setTrigger(prev => !prev)}
          >
            <span />
            <span />
            <span />
          </StyledBurgerMenuWrapper>
        </div>
      </StyledInnerContainer>
    </HeaderContainer>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
