import React from "react"

//SOCIAL_MEDIA_ICONS
import Instagram from "../assets/svg/instagram.svg"
import Facebook from "../assets/svg/facebook.svg"
import Youtube from "../assets/svg/youtube.svg"

// OUR_SERVICES_ICONS
import VideoIcon from "../assets/svg/video-camera.svg"
import GraphicsIcon from "../assets/svg/motion-graphics.svg"
import CameraIcon from "../assets/svg/photo-camera.svg"

export const SOCIAL_MEDIA_ICON = [
  {
    id: 1,
    icon: <Instagram />,
    href: "https://www.instagram.com/megproduction_am/?utm_medium=copy_link",
  },
  {
    id: 2,
    icon: <Facebook />,
    href: "https://www.facebook.com/MEGProductionOfficial/",
  },
  {
    id: 3,
    icon: <Youtube />,
    href: "https://www.youtube.com/channel/UCywF2n6ikxP6w7lUx-u0kIg",
  },
]

export const NAV_ITEMS = [
  { id: 0, location: "/", name: "HOME" },
  { id: 1, location: "/about-us/", name: "ABOUT US" },
  {
    id: 2,
    location: "/our-work/",
    name: "OUR WORK",
    children: [
      {
        id: 0,
        location: "/our-work/commercial-photography/",
        name: "Photography",
      },
      {
        id: 1,
        location: "/our-work/video-production/",
        name: "Video Production",
      },
      {
        id: 2,
        location: "/our-work/2d-3d-animation/",
        name: "2D/3D Animation",
      },
    ],
  },
  { id: 3, location: "/blog/", name: "BLOG" },
  { id: 4, location: "/contact/", name: "CONTACT" },
]

export const OUR_SERVICES = [
  {
    id: 1,
    location: "/our-work/?project=commercial-photography",
    title: "Commercial Photography",
    description:
      "Our photographers, in keeping up with times, study and test the latest photography equipment, as well as the various lights offered on the market. As a result, they know how to photograph your product or event to get the best photos.",
    icon: <CameraIcon />,
  },
  {
    id: 0,
    location: "/our-work/?project=video-production",
    title: "Video Production",
    description:
      "The experienced shooting team of MEG Production is constantly improving its knowledge, and we use the best professional equipment for shooting.",
    icon: <VideoIcon />,
  },
  {
    id: 2,
    location: "/our-work/?project=2d-3d-animation",
    title: "2D/3D Animation",
    description:
      "Our talented 2D & 3D animation staff pay special attention to each job to accomplish highly specific business goals.",
    icon: <GraphicsIcon />,
  },
  // {
  //   id: 3,
  //   location: "/our-work/?project=graphic-design",
  //   title: "Graphic Design",
  //   description:
  //     "As a result of the work of our skilled graphic designers, your company will be presented in the market with a unique, original, creative and attractive design.",
  //   icon: <FluentIcon />,
  // },
]

export const YEAR = +new Date(Date.now()).getFullYear() - 2020

export const COLUMNS_LOCATION = [5, 15, 25, 35, 45, 55, 65, 75, 85, 95]

export const HEADER_BG = "rgba(18, 36, 54, 0.6)"
